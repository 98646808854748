import Header from "components/header/HeaderAuthDefault";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { FiDownload, FiTrash, FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import httpService from "services/http-service";
import { getLocalStorageKey } from "utils/utils";
import logo from "assets/img/logo/logo.png";
import { getLocalStorageValue } from "utils/utils";

export default function Gallery() {

  const eventId = getLocalStorageKey("eventId");
  const [loading, setLoading] = useState(false);

  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageDetails, setImageDetails] = useState([]);
  const [base64String, setBase64String] = useState("");

  useEffect(() => {


    // loadImages();
  }, [images]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      // Read file as data URL (Base64)
      reader.onload = () => {
        const base64String = reader.result.split(",")[1]; // Exclude the MIME type

        setBase64String(base64String); // Set the Base64 string
       
        uploadEventImage(base64String)
      };

      reader.readAsDataURL(file); // Start reading the file
    }
  };
  const uploadEventImage = async (imageBase64) => {
    setLoading(true);
    const endpoint = "/EventContent";
    const userId = getLocalStorageValue("userDetails", "userId");

    const data = {

      eventId: eventId,
      userId: userId,
      content: "",
      type: "Image",
      base64: imageBase64 || base64String
    };

    const response = await httpService("POST", endpoint, data);
    if (!response) {
      setLoading(false);
      toast.error("Error updating photo");
    } else {
      setBase64String(null)
      toast.success("Photo added successfully");
      populateData();
    }
  }
  const loadImages = async () => {
    const details = await Promise.all(
      images.map((img) => {
        return new Promise((resolve) => {
          const imgElement = new Image();
          imgElement.src = img;
          imgElement.onload = () => {
            resolve({
              src: img,
              isLandscape: imgElement.width > imgElement.height,
            });
          };
        });
      })
    );
    setImageDetails(details);
  };

  useEffect(() => {
    populateData();

  }, []);

  const populateData = async () => {
    setLoading(true);

    const endpoint = `/EventContent/GetByEvent/${eventId}`;
    const response = await httpService("GET", endpoint);

    if (!response) {
      setLoading(false);
      toast.error('Something went wrong');
    } else {
      setLoading(false);
      console.log('resp', response)
      setImages(response || [])

      loadImages();
    }
  };

  const handleDeleteEventImage = async (eventContentId) => {
    setLoading(true);
    const endpoint = `/EventContent/${eventContentId}`;
    const response = await httpService("DELETE", endpoint);
    if (!response) {
      setLoading(false);
      toast.error('Error occured while deleting');
    } else {
      setLoading(false);
      toast.success(response || "Image deleted successfully!");

      populateData();
      setSelectedImage(null)
    }
  };

  const handleImageClick = (img) => {
    setSelectedImage(img);
  };

  // Function to handle downloading image
  const handleDownload = async (img) => {
    try {
      const response = await fetch(img); // Fetch the image
      if (!response.ok) {
        throw new Error("Failed to fetch the image.");
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "image.jpg";
      link.click();

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };


  // Function to handle deleting image with PIN protection
  const handleAddDelete = (eventContentId, pin, isDeletion = false) => {
    Swal.fire({
      text: "Enter 4 digit Security PIN for this event to add/delete photos",
      input: "password",
      inputAttributes: {
        maxlength: 4,
        minlength: 4,
        autocapitalize: "off",
        autocorrect: "off",
        inputmode: "numeric",
      },
      inputPlaceholder: "Enter 4 digit PIN",
      showCancelButton: true,
      confirmButtonText: "Verify",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "bg-blue-600 text-white hover:bg-blue-700 focus:ring-2 focus:ring-blue-300 rounded-md py-2 px-4",
        cancelButton: "bg-red-600 text-white hover:bg-red-700 focus:ring-2 focus:ring-red-300 rounded-md py-2 px-4",
      },
      inputValidator: (value) => {
        if (!value) {
          return "Please enter a PIN!";
        }
        if (value.length < 4 || !/^\d+$/.test(value)) {
          return "PIN must be 4 digits!";
        }
        if (value != pin) {
          return "Please enter a valid PIN!";
        }
        return null;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        toast.success("Access Granted - PIN validated successfully!");

        if (isDeletion) {
          handleDeleteEventImage(eventContentId)

        } else {
          document.getElementById('photo-upload').click()
        }

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        toast.error("Cancelled - You cancelled the PIN validation");
      }
    });
  };
  const isLandscape = (img) => {
    console.log(img?.orientation, 'img')
    return  img?.orientation === "Landscape" ? false : true;
  };

  return (
    <div className="min-h-screen">
      <div className="ml-2 mt-[6px] mb-[4px] w-full max-w-full flex items-center justify-between md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <div className="flex items-center text-2xl font-bold text-navy-700 dark:text-white">
          <img className="h-[33px] w-[112px]" src={logo} alt="logo" />
        </div>

        <button
          className="px-4 py-2 border-2 border-blue-600 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white transition focus:outline-none focus:ring focus:ring-blue-300"
          onClick={() => handleAddDelete(0, images[0]?.pin)}

        >
          Add Photos
        </button>
        <input
          id="photo-upload"
          type="file"
          accept="image/*"
          className="hidden"
          onChange={handleFileChange}
        />
      </div>

      <Loading loading={loading} loaderColor="#252424" />
      {/* <div
  className={`grid gap-1 ${
    images.some((img) => isLandscape(img))
      ? 'grid-cols-1 sm:grid-cols-1'
      : 'grid-cols-2 sm:grid-cols-2'
  }`}
>
  {images.map((img, index) => (
    <div
      key={index}
      className={`relative overflow-hidden rounded-none ${
        isLandscape(img) ? 'col-span-1 sm:col-span-1' : 'col-span-2 sm:col-span-2'
      }`}
    >
      <img
        src={img?.content}
        alt={`Gallery Image ${index + 1}`}
        className="w-full h-48 object-cover transition-transform transform hover:scale-110 cursor-pointer"
        onClick={() => handleImageClick(img)}
      />
    </div>
  ))}
</div> */}


      {/* Gallery Grid */}
      <div className="grid grid-cols sm:grid-cols gap-1">
        {images.map((img, index) => (
          <div
            key={index}
            className={`relative overflow-hidden rounded-none ${
              // Dynamically set the column span for landscape images
        isLandscape(img) ? 'col-span-1 sm:col-span-1' : 'col-span-2 sm:col-span-2'
              }`}
          >
            <img
              src={img?.content}
              alt={`Gallery Image ${index + 1}`}
              className="w-full h-48 object-cover transition-transform transform hover:scale-110 cursor-pointer"
              onClick={() => handleImageClick(img)}
            />
          </div>
        ))}
      </div>


      {selectedImage && (
        <div className="fixed inset-0 bg-white flex flex-col items-center justify-center z-50">

          <div className="w-full bg-gray-100 py-3 px-4 border-b border-gray-300 flex items-center justify-between">
            <h2 className="text-lg font-semibold text-gray-900">Preview Image</h2>
            <button
              className="text-gray-500 hover:text-gray-700"
              onClick={() => setSelectedImage(null)}
            >
              <FiX />
            </button>
          </div>

          <div className="w-full h-full flex items-center justify-center">
            <img
              src={selectedImage?.content}
              alt="Preview"
              className="max-w-full max-h-full object-contain"
            />
          </div>
          <div className="absolute bottom-0 w-full bg-white py-4 flex justify-around border-t border-gray-300">
            <button
              className="text-blue-500 text-2xl hover:text-blue-600"
              onClick={() => handleDownload(selectedImage?.content)}
              title="Download"
            >
              <FiDownload />
            </button>
            <button
              className="text-red-500 text-2xl hover:text-red-600"
              onClick={() => handleAddDelete(selectedImage?.id,selectedImage?.pin, true)}
              title="Delete"
            >
              <FiTrash />
            </button>
            <button
              className="text-gray-500 text-2xl hover:text-gray-600"
              onClick={() => setSelectedImage(null)}
              title="Close"
            >
              <FiX />
            </button>
          </div>
        </div>
      )}


    </div>
  );
}
